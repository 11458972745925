import React, { useState } from "react";
import Layout from "../../layouts/index";
import SEO from "../../components/seo";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import ScrollToTop from "../../components/scroll-to-top";
import { NavLink } from "react-router-dom";
import * as auth from "../../shared/auth-service/auth";
import { ToastContainer, toast } from "react-toastify";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  //validation
  const validate = () => {
    let isValid = true;
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  //Google auth
  const googleOauth = (e) => {
    e.preventDefault();
    const authUrl = `${process.env.REACT_APP_BASE_URL}/auth/google`;
    window.location.href = authUrl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        const response = await auth.userRegister({ email, password });
        toast.success(response?.data?.result);
      } catch (error) {
        const { result } = error?.response?.data;
        toast.error(result);
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Layout>
        <SEO title="8th Street Studio || Register" />
        <div className="wrapper home-default-wrapper">
          <Header classOption="hb-border" />
          <div style={{ position: "relative", top: "120px" }}>
            <h1 className="text-center fw-bold">REGISTER</h1>
          </div>

          <div className="container">
            <section className="vh-100">
              <div className="container py-5 h-100">
                <div className="row d-flex align-items-center justify-content-center h-100">
                  <div className="col-md-8 col-lg-7 col-xl-6">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                      className="img-fluid"
                      alt="Phone"
                    />
                  </div>
                  <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                    <form onSubmit={handleSubmit}>
                      <div data-mdb-input-init className="form-outline mb-4">
                        <label className="form-label" htmlFor="form1Example13">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && (
                          <p className="text-danger">{emailError}</p>
                        )}
                      </div>

                      <div data-mdb-input-init className="form-outline mb-4">
                        <label className="form-label" htmlFor="form1Example23">
                          Password
                        </label>
                        <input
                          type="password"
                          id="form1Example23"
                          className="form-control form-control-lg"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordError && (
                          <p className="text-danger">{passwordError}</p>
                        )}
                      </div>

                      <div className="d-flex  align-items-center mb-4">
                        Have already an account?
                        <span className="ms-3">
                          <NavLink to="/login">Login here</NavLink>
                        </span>
                      </div>

                      <button
                        type="submit"
                        data-mdb-button-init
                        data-mdb-ripple-init
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Sign up
                      </button>

                      <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0 text-muted">
                          OR
                        </p>
                      </div>

                      <a
                        data-mdb-ripple-init
                        className="btn btn-primary btn-lg btn-block"
                        style={{ backgroundColor: "#3b5998" }}
                        href="#!"
                        role="button"
                      >
                        <i className="fab fa-facebook-f me-2"></i>Continue with
                        Facebook
                      </a>
                      <button
                        className="btn btn-danger btn-lg btn-block mt-3"
                        style={{ backgroundColor: "#DB4437" }}
                        onClick={googleOauth}
                      >
                        <i className="fab fa-google me-2 px-2"></i>Continue with
                        Google
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Register;
