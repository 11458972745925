import axios from "axios";

//login
export const userLogin = (payload) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signin`, payload);
};

//register
export const userRegister = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/signUpUsingEmail`,
    payload
  );
};

//update user by id
export const updateUser = (id, payload) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/user-management/update-specific-user/${id}`,
    payload
  );
};

//get user by id
export const getUserById = (id) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/user-management/get-specific-user/${id}`
  );
};

//google Oauth
export const Oauth = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/auth/google`);
};

//forgot password
export const forgotPassword = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/forget-password`,
    payload
  );
};

//reset password
export const resetPassword = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/reset-password`,
    payload
  );
};
