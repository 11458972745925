import React from 'react'
import Footer from '../../../layouts/footer';
import camera_image from '../../../assets/images/Film Camera.gif'
import PageTitle from '../../../components/page-title';


function Dashboard() {
  return (
<>
<div className='dashboard_Component vh-100'>
<div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle="8th Studio"
                            title="We Are 8th Studio"  
                        />
                    </div>
                </div>
            </div>
        </div>
 <div className='row'>
  <div className='col-lg-12 col-md-12 col-12'>
    <div className='container-xxl'>
  <div className='row'>
    <div className='col-lg-6 col-md-6 col-12'>
      <div className='dashboard_box'>
        <h1>Welcome To Admin Dashboard</h1>
        <p className='text-center'>
          This dashboard allows you to manage all aspects of the platform efficiently. 
          You can oversee user activity, manage content, and configure settings.
        </p>
      </div>
    </div>
    <div className='col-lg-6 col-md-6 col-12'>
      <div className='image_box'>
        <img src={camera_image} style={{borderRadius:'30px' , marginTop:'60px'}} alt='Film Camera' className='img-fluid' />
      </div>
    </div>
  </div>
</div>

  </div>
 </div>
</div>

</>
  )
}

export default Dashboard;
