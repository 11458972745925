import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../layouts/footer";
import PageTitle from "../../../components/page-title";
import * as StudioCategoryBooking from "../../../shared/studio-booking-service/studio-booking";
import * as StudioCategoryService from "../../../shared/studio-category-service/studio-category";

function BookingList() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [studioCategoryData, setStudioCategoryData] = useState([]);
  const currentItems = studioCategoryData;
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [hours, setHours] = useState("");
  const [rate, setRate] = useState("");
  const [HourlyRateThumbnail, setHourlyRateThumbnail] = useState("");
  const [updateId, setUpdateId] = useState("");
  const baseUrl = `${process.env.REACT_APP_IMAGE_URL}/`;

  useEffect(() => {
    getBookingList();
    getStudioCategory();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const image = e.target.files && e.target.files[0];
      setHourlyRateThumbnail(image);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  //get list of booking list
  const getBookingList = async () => {
    const response = await StudioCategoryBooking.getBookingList();
    setStudioCategoryData(response.data.body);
  };

  //get booking by id
  const getBookingById = async (id) => {
    setUpdateId(id);
    const response = await StudioCategoryBooking.getBookingById(id);
    const data = response?.data?.body;
    const Image = data?.HourlyRateThumbnail?.imageUrl;
    // setImagePreview(baseUrl + Image);
    setImagePreview(Image);

    setHours(data?.hours);
    setRate(data?.rate);
    setCategory(data?.category?._id);
  };

  // get studio category
  const getStudioCategory = async () => {
    const studioCategory = await StudioCategoryService.getStudioCategory();
    const categoryData = studioCategory?.data?.body.map(
      ({ _id, categoryName }) => ({
        id: _id,
        name: categoryName,
      })
    );
    setCategories(categoryData);
  };

  //update studio category by id
  const updateBookingById = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("hours", hours), formData.append("rate", rate);
    if (HourlyRateThumbnail) {
      formData.append("HourlyRateThumbnail", HourlyRateThumbnail);
    }
    if (category) {
      formData.append("category", category);
    }
    try {
      const response = await StudioCategoryBooking.updateBookingById(
        updateId,
        formData
      );
      console.log("response", response);
      toast.success(response?.data?.result);
      getBookingList();
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  //delete studio category by id
  const deleteBookingById = async (id) => {
    try {
      const response = await StudioCategoryBooking.deleteBookingById(id);
      setStudioCategoryData(
        studioCategoryData.filter((booking) => booking._id !== id)
      );
      toast.success(response?.data?.result);
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Prime React Table */}
      <div className="bookingList_Component">
        <div className="page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <PageTitle
                  classOption="page-title-content content-style2 text-center"
                  subTitle="Studio Booking List"
                  title="We Are 8th Studio"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Prime React Table */}
        <div className="bookingList_div">
          <div className="p-inputgroup mt-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </div>

          <DataTable
            value={currentItems}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={rows}
            first={first}
            onPage={onPageChange}
            globalFilter={globalFilter}
            emptyMessage="No studio category found."
            responsiveLayout="scroll"
            style={{
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <Column
              field="index"
              header="#"
              body={(rowData, options) => first + options.rowIndex + 1}
            ></Column>
            <Column
              header="Thumbnail"
              body={(rowData) => (
                <img
                  src={
                    rowData.HourlyRateThumbnail.imageUrl ||
                    rowData.HourlyRateThumbnail
                  }
                  alt={rowData.categoryName}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                />
              )}
            ></Column>
            <Column field="hours" header="Hours" body={(rowData) => `${rowData.hours} hour`}></Column>
            <Column field="rate" header="Rate" body={(rowData) => `£ ${rowData.rate}`}></Column>
            <Column
              field="category.categoryName"
              header="Category Name"
            ></Column>
            <Column
              header="Edit"
              body={(rowData) => (
                <i
                  data-bs-toggle="modal"
                  data-bs-target="#updateModal"
                  className="fa fa-pencil text-info"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => getBookingById(rowData._id)}
                ></i>
              )}
            ></Column>
            <Column
              header="Delete"
              body={(rowData) => (
                <i
                  className="fa fa-trash-o text-danger"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => deleteBookingById(rowData._id)}
                ></i>
              )}
            ></Column>
          </DataTable>
        </div>
        {/* Prime React Table */}

        {/* modal start */}
        <div
          className="modal fade"
          id="updateModal"
          tabIndex="-1"
          aria-labelledby="updateModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="updateModalLabel">
                  Update Studio Booking
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col-lg-5 d-flex flex-column align-items-center justify-content-center text-white p-4"
                    style={{
                      borderRadius: "20px 0 0 20px",
                    }}
                  >
                    <div className="profile-pic-container">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Product"
                          className="rounded"
                          style={{
                            width: "250px",
                            height: "250px",
                            objectFit: "cover",
                            border: "4px solid white",
                          }}
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/150"
                          alt="Product"
                          className="rounded"
                          style={{
                            width: "250px",
                            height: "250px",
                            objectFit: "cover",
                            border: "4px solid white",
                          }}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="d-none"
                      id="productImageUpload"
                    />
                    <label
                      htmlFor="productImageUpload"
                      className="btn btn-light mt-3"
                    >
                      Update Image
                    </label>
                    <p className="mt-3">You can upload image (jpg or png)</p>
                  </div>

                  {/* Right Side - Form Section */}
                  <div className="col-lg-7">
                    <div className="text-center mb-4">
                      <h2>Update Studio Booking</h2>
                    </div>
                    <form>
                      <div className="mb-3">
                        <label htmlFor="category" className="form-label">
                          Select Category
                        </label>
                        <select
                          className="form-select"
                          value={category}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setCategory(selectedValue);
                          }}
                        >
                          <option value="" disabled>
                            Select category
                          </option>
                          {categories.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="hours" className="form-label">
                          Enter Hours
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="hours"
                          value={hours}
                          onChange={(e) => setHours(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="rate" className="form-label">
                          Enter Rate
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="rate"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={updateBookingById}
                  type="button"
                  className="btn btn-success"
                >
                  Confirm Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}

      </div>
      {/* Prime React Table */}
    </>
  );
}

export default BookingList;
