import axios from "axios";

//create Studio Booking
export const createBooking = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/create-hourly-rate`,
    payload
  );
};

//list Studio Booking
export const getBookingList = () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/get-all-hourly-rates`
  );
};

//get booking by id
export const getBookingById = (id) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/get-hourly-rate-by-id/${id}`
  );
};

//update booking by id
export const updateBookingById = (id, payload) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/update-hourly-rate/${id}`,
    payload
  );
};

//delete studio category
export const deleteBookingById = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/delete-hourly-rate-by-id/${id}`
  );
};

//get all studio booking list against it's category id
export const getStudioCategoryListById = (categoryId) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/hourly-rates-management/get-hourly-rates-by-category-id/${categoryId}`
  );
};

//check-Time-Slot-Availability
export const checkTimeSlot = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/bookings-management/check-Time-Slot-Availability`,
    payload
  );
};

//create stripe customer
export const createStripeCustomer = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/payments/create-customer`,
    payload
  );
};

//create stripe intent
export const createPaymentIntent = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/payments/create-payment-intent`,
    payload
  );
};

//create customer booking
export const createCustomerBooking = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/bookings-management/create-booking`,
    payload
  );
};

//get all booked customer list
export const getBookedCustomerList = () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/bookings-management/get-all-bookings`
  );
};

//delete booked customer list
export const deleteBookedCustomer = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_BASE_URL}/bookings-management/delete-booking-by-id/${id}`
  );
};