import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Logo = ({ image }) => {
    return (
        <Link to={ "/"}>
            <img
                className="logo-main"
                style={{width:'150px' , height:'150px'}}
                src={image}
                alt="Logo"
            />
            <img
                className="logo-light"
                style={{width:'150px' , height:'150px'}}
                src={image}
                alt="Logo"
            />
        </Link>
    );
};

Logo.propTypes = {
    image: PropTypes.string,
};

export default Logo;
