import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../layouts/footer";
import PageTitle from "../../../components/page-title";
import * as StudioCategoryBooking from "../../../shared/studio-booking-service/studio-booking";
import * as Utils from "../../../utils/utils"

const CustomersList = () => {
  const [studioCustomersList, setStudioCustomerList] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [globalFilter, setGlobalFilter] = useState("");
  const currentItems = studioCustomersList;


  useEffect(() => {
    getCustomersList();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  //get all customers list
  const getCustomersList = async () => {
    const response = await StudioCategoryBooking.getBookedCustomerList();
    console.log(response);
    setStudioCustomerList(response?.data.body)
  };

    //delete studio category by id
    const deleteCustomerById = async (id) => {
        try {
          const response = await StudioCategoryBooking.deleteBookedCustomer(id);
          setStudioCustomerList(
            studioCustomersList.filter((booking) => booking._id !== id)
          );
          toast.success(response?.data?.result);
        } catch (error) {
          const { result } = error?.response?.data;
          toast.error(result);
        }
      };


  return (
    <>
      <ToastContainer />
      {/* Prime React Table */}
      <div className="bookingList_Component">
        <div className="page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <PageTitle
                  classOption="page-title-content content-style2 text-center"
                  subTitle="Studio Customers List"
                  title="We Are 8th Studio"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Prime React Table */}
        <div className="bookingList_div">
          <div className="p-inputgroup mt-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </div>

          <DataTable
            value={currentItems}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={rows}
            first={first}
            onPage={onPageChange}
            globalFilter={globalFilter}
            emptyMessage="No studio category found."
            responsiveLayout="scroll"
            style={{
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <Column
              field="index"
              header="#"
              body={(rowData, options) => first + options.rowIndex + 1}
            ></Column>
            <Column
              field="customerName"
              header="Name"
            ></Column>
            <Column
              field="bookingStartTime"
              header="Start Time"
              body={(rowData) => `${Utils.formatDateCustomer(rowData.bookingStartTime) }`}
            ></Column>
            <Column
              field="bookingEndTime"
              header="End Time"
              body={(rowData) => `${Utils.formatDateCustomer(rowData.bookingEndTime) }`}
            ></Column> 
            paymentStatus
            <Column
              field="customerPhone"
              header="Phone"
            ></Column> totalAmountPaid

            <Column
              field="paymentStatus"
              header="Status"
              className="text-success"
            ></Column>
            <Column field="totalAmountPaid" header="Amount" body={(rowData) => `£ ${rowData.totalAmountPaid}`}></Column>
          
            <Column
              header="Delete"
              body={(rowData) => (
                <i
                  className="fa fa-trash-o text-danger"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => deleteCustomerById(rowData._id)}
                ></i>
              )}
            ></Column>
          </DataTable>
        </div>
      </div>
      {/* Prime React Table */}
    </>
  );
};

export default CustomersList;
