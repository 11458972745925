import PropTypes from "prop-types";
import { Link } from "react-router-dom";
const FooterLogo = ({ image }) => {
    return (
        <Link to={"/"}>
            <img
                style={{width:'290px'}}
                src={image}
                alt="Logo"
            />
        </Link>
    );
};

FooterLogo.propTypes = {
    image: PropTypes.string,
};

export default FooterLogo;
