import moment from 'moment';


//save token
export const setUserLoginToken = (data) =>{
  return localStorage.setItem('token', data)
}

//get token
export const getUserLoginToken = () =>{
    return localStorage.getItem('token')
}

//save user data
export const saveUserData = (data) =>{
  return localStorage.setItem('user-data',JSON.stringify(data))
}

//get user data
export const getUserData = () =>{
  return JSON.parse(localStorage.getItem('user-data'))
}

//remove user token
export const deleteToken = () =>{
  return localStorage.removeItem('token')
}

//remove user data
export const deleteUserData = () =>{
  return localStorage.removeItem('user-data')
}

//save customer booking date and time
export const saveBookingDate = (data) =>{
  localStorage.setItem('customerBookingTime',JSON.stringify(data))
}

//get customer booking date and time
export const getBookingDate = () =>{
  return JSON.parse(localStorage.getItem('customerBookingTime'))
}

//save customer hourly id
export const saveHourlyId = (id) =>{
  localStorage.setItem('hourlyRateId',JSON.stringify(id))
}

//get customer hourly id
export const getHourlyId = () =>{
  return JSON.parse(localStorage.getItem('hourlyRateId'))
}

//save selected booking
export const saveSelectedBooking = (data) =>{
  localStorage.setItem('booking',JSON.stringify(data))
}

//get selected booking
export const getSelectedBooking = () =>{
  return JSON.parse(localStorage.getItem('booking'))
}


//format date as YYYY-MM-DD using moment
export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

//format date as DD-MM-YYYY using moment
export const formatDateCustomer = (date) => {
  return moment(date).format('DD-MM-YYYY');
};

