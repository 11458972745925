import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import * as StudioBooking from "../shared/studio-booking-service/studio-booking";
import * as Utils from "../utils/utils";

const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const HandlePayment = ({ customerData, onInputChange, onPaymentSuccess }) => {
  const getCustomerBookingDateTime = Utils.getBookingDate();
  const getCustomerBooking = Utils.getSelectedBooking();
  const [error, setError] = useState(null);
  const [isPaymentInitialized, setPaymentInitialized] = useState(false);
  const customerId = customerData?.data?.id;
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (customerData && !isEmptyObject(customerData) && stripe && elements) {
      if (!isPaymentInitialized) {
        handlePayment();
        setPaymentInitialized(true);
      }
    }
  }, [customerData, stripe, elements, onPaymentSuccess ]);

  const handlePayment = async () => {
    if (!stripe || !elements) return;

    try {
      const response = await StudioBooking.createPaymentIntent({
        amount: getCustomerBooking.rate * 100 ,
        currency: "gbp",
        customerId,
      });
      const clientSecret = response.data.clientSecret;
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),

          billing_details: {
            name: customerData?.data?.name,
            email: customerData?.data?.email,
            phone: customerData?.data?.phone,
            address: {
              line1: customerData?.data?.address?.line1,
              city: customerData?.data?.address?.city,
              postal_code: customerData?.data?.address?.postal_code,
            },
          },
        },
      });
      if (result.error) {
        setError(result.error.message);
        console.error(result.error.message);
        onPaymentSuccess(false);
      } else if (result.paymentIntent.status === "succeeded") {
        await createCustomerBooking();
      }
    } catch (error) {
      onPaymentSuccess(false);
      console.error("Payment error:", error);
    }
  };

  // create customer booking
  const createCustomerBooking = async () => {
    try {
      const response = await StudioBooking.createCustomerBooking({
        customerName: customerData?.data?.name,
        customerEmail: customerData?.data?.email,
        customerPhone: customerData?.data?.phone,
        category: getCustomerBookingDateTime.category,
        hourlyRates: getCustomerBooking._id,
        totalAmountPaid: getCustomerBooking.rate * 100,
        bookingDate: getCustomerBookingDateTime.bookingDate,
        bookingStartTime: getCustomerBookingDateTime.bookingStartTime,
        paymentStatus: "Paid",
        bookingEndTime: "11:00 AM",
      });
      if (response?.data?.isData === true) {
        console.log(
          "resulttttttttttttttttttttttttttttt",
          response?.data?.isData
        );
        onPaymentSuccess(true);
      } else {
        onPaymentSuccess(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handlePayment}>
        <div className="form-group">
          <label htmlFor="card-element">Card Details</label>
          <div id="card-element" className="card-element-input">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#32325d",
                    "::placeholder": { color: "#aab7c4" },
                    padding: "10px",
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
              onChange={(event) => {
                const value = event.value;
                onInputChange(value.postalCode);
              }}
            />
          </div>
          {error && <div className="text-danger">{error}</div>}
        </div>
      </form>
    </>
  );
};
export default HandlePayment;
