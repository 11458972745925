import React, { useState } from "react";
import Layout from "../../layouts/index";
import SEO from "../../components/seo";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import ScrollToTop from "../../components/scroll-to-top";
import { ToastContainer, toast } from "react-toastify";
import * as auth from "../../shared/auth-service/auth";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  //validation
  const validate = () => {
    let isValid = true;
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid.");
      isValid = false;
    } else {
      setEmailError("");
    }
    return isValid;
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await auth.forgotPassword({ email });
        toast.success(response?.data?.result);
        console.log(response);
      } catch (error) {
        const { result } = error?.response?.data;
        console.log(error);
        toast.error(result);
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Layout>
        <SEO title="8th Street Studio || Forgot Password" />
        <div className="wrapper home-default-wrapper">
          <Header classOption="hb-border" />
          <div style={{ position: "relative", top: "235px" }}>
            <h1 className="text-center fw-bold">FORGOT PASSWORD</h1>
          </div>

          <div className="container vh-100">
            <form onSubmit={forgotPassword}>
              <div
                className="row"
                style={{ marginTop: "280px", marginLeft: "299px" }}
              >
                <div className="col-md-10 col-lg-10 mt-5 " style={{}}>
                  <p>
                    Enter your email address below and we will send you a link
                    to reset your password.
                  </p>
                  <div className="form-group mt-5">
                    <label className="fw-bold h5 mb-4">Email address</label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  {emailError && <p className="text-danger">{emailError}</p>}
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default ForgotPassword;
