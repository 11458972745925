import { useHistory } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component,
  restricted,
  path,
  ...rest
}) => {
  const getToken = localStorage.getItem("token") !== null;
  const history = useHistory()
  return !restricted || getToken ? (
    <Component {...rest} />
  ) : (
    history.push("/login")
  );
};


export default ProtectedRoute;