import AOS from "aos";
import "aos/dist/aos.css";
import "lightgallery.js/dist/css/lightgallery.css";
import { useEffect } from "react";
import "react-modal-video/scss/modal-video.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
// CSS File Here
import "./assets/css/elegantIcons.css";
import "./assets/css/icofont.css";
import "./assets/scss/style.scss";
import 'react-phone-number-input/style.css'
import NavScrollTop from "./components/nav-scroll-top";
import AboutPage from "./pages/about";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import HomePage from "./pages/index";
import Portfolio from "./pages/portfolio";
import VideoGallery from "./pages/video-gallery";
import BlogAuthor from "./templates/blog-author";
import BlogCategory from "./templates/blog-category";
import BlogDate from "./templates/blog-date";
import BlogDetails from "./templates/blog-details";
import BlogTag from "./templates/blog-tag";
import PortfolioDetails from "./templates/portfolio-details";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import UserProfile from "./pages/user/user-profile";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import SideNav from "./pages/admin/sidenav/sidenav";
import ProtectedRoute from "./protectedRoute";
import Dashboard from "./pages/admin/dashboard/dashboard";
import Booking from "./pages/booking/booking";
import StudioCategory from "./pages/admin/studio-category/studio-category";
import ConfirmBooking from "./pages/booking/confirm-booking";
import PaymentCard from "./pages/booking/payment-card";
import BookingConfirmation from "./pages/booking/booking-confirmation";
const App = () => {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/home" exact component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/video-gallery" component={VideoGallery} />
          <Route path="/portfolio-details/:id" component={PortfolioDetails} />
          <Route path="/blog" component={Blog} />
          <Route path="/category/:slug" component={BlogCategory} />
          <Route path="/tag/:slug" component={BlogTag} />
          <Route path="/date/:date" component={BlogDate} />
          <Route path="/author/:author" component={BlogAuthor} />
          <Route path="/blog-details/:id" component={BlogDetails} />
          <Route path="/contact" component={Contact} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/my-account" component={UserProfile} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/booking" component={Booking} />
          <Route path="/confirm-booking" component={ConfirmBooking} />
          <Route path="/booking-confirmation" component={BookingConfirmation} />
          <Route path="/reset-password/:id/:verificationToken/:token" component={ResetPassword} />
          <Route path="/sideNav" component={() => <ProtectedRoute component={SideNav} restricted={true} />} />          <Route exact path="/sideNav/dashboard" component={Dashboard} />
          <Route exact path="/sideNav/create-studio-category" component={StudioCategory} />
          
        </Switch>
      </NavScrollTop>
    </Router>
  );
};

export default App;
