import React, { useState, useEffect } from "react";
import { Steps } from "primereact/steps";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import Layout from "../../layouts/index";
import SEO from "../../components/seo";
import Header from "../../layouts/header/index";
import PhoneInput from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import PaymentCard from "./payment-card.js";
import HandlePayment from "../../utils/handle-payment.js";
import BookingConfirmation from "./booking-confirmation.js";
import * as StudioBooking from "../../shared/studio-booking-service/studio-booking.js";
import Confetti from "react-confetti";

const stripePromise = loadStripe(
  "pk_test_51OtRDuSBR6ewkHIDpLTJc624qoX30grIza7C30DUvROMOdmtLAK9zCORCWDbRbr29VWbGHWmkJ0o7Fi6Bfk2xLcZ009akKk5Eu"
);

const ConfirmBooking = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [line1, setLine1] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [selected, setSelected] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const location = useLocation();
  const { category, bookingStartTime, bookingEndTime } = location.state;
  console.log(category, bookingStartTime, bookingEndTime);

  const items = [
    { label: "Payment", icon: "pi pi-user" },
    { label: "Confirm", icon: "pi pi-check" },
  ];

  useEffect(() => {
    const checkFormValidity = () => {
      if (
        name !== "" &&
        email !== "" &&
        value !== "" &&
        selected !== "" &&
        line1 !== "" &&
        city !== "" &&
        state !== "" &&
        postal_code !== "" &&
        inputValue !== ""
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };
    checkFormValidity();
  }, [
    name,
    email,
    value,
    selected,
    line1,
    city,
    state,
    postal_code,
    inputValue,
  ]);

  const handleInputChange = (newValue) => {
    console.log("Value from child:", newValue);
    setInputValue(newValue);
  };

  const handlePaymentStatus = (status) => {
    if (status === true) {
      setLoading(false);
      setActiveIndex((prev) => Math.min(prev + 1, items.length - 1));
    }else if (status === '' || status === false){
      setLoading(false);
    }
  };

  //create stripe customer
  const createCustomer = async () => {
    setLoading(true);
    try {
      const response = await StudioBooking.createStripeCustomer({
        name: name,
        email: email,
        phone: value,
        line1: line1,
        postal_code: postal_code,
        city: city,
        state: state,
        country: selected,
      });
      setCustomerData(response);
    } catch (error) {}
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="createBooking_Component">
        <Layout>
          <SEO title="Alexis || Booking" />
          <div className="wrapper about-page-wrapper">
            <Header classOption="hb-border" />
            <div className="container confirm_booking">
              <Steps
                model={items}
                activeIndex={activeIndex}
                onSelect={(e) => setActiveIndex(e.index)}
              />

              {/* Conditional Rendering of Step Content */}
              {activeIndex === 0 && (
                // <div className="d-flex justify-content-between">
                <div className="row">
                  <div className="col-md-8">
                    <form>
                      <div class="mb-3 row">
                        <label for="name" class="col-form-label">
                          Name <span class="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Full Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="email" class="form-label">
                          Email <span class="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="phone" class="form-label">
                          Phone <span class="text-danger">*</span>
                        </label>
                        <PhoneInput
                          international
                          defaultCountry="RU"
                          value={value}
                          onChange={setValue}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="phone" class="form-label">
                          Country <span class="text-danger">*</span>
                        </label>
                        <ReactFlagsSelect
                          selected={selected}
                          onSelect={(code) => {
                            setSelected(code);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="line1" class="form-label">
                          Address Line 1 <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Street Address"
                          value={line1}
                          onChange={(e) => {
                            setLine1(e.target.value);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="city" class="form-label">
                          City <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="City"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="state" class="form-label">
                          State/Province <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="State/Province"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="postal_code" class="form-label">
                          Postal Code <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Postal Code"
                          value={postal_code}
                          onChange={(e) => {
                            setPostalCode(e.target.value);
                          }}
                        />
                      </div>
                    </form>

                    <Elements stripe={stripePromise}>
                      <HandlePayment
                        customerData={customerData}
                        onInputChange={handleInputChange}
                        onPaymentSuccess={handlePaymentStatus}
                      />
                    </Elements>
                  </div>
                  <div className="payment-section col-md-4">
                    <PaymentCard />
                  </div>
                </div>
                // </div>
              )}

              <div >
             
                {activeIndex === 1 && (
                  
                  <div>
                  <BookingConfirmation />
                  
                  <Confetti />
                </div>
              ) }
              </div>
              {/* Navigation Buttons */}
              {activeIndex !== 1 && (
                <div className="step-navigation d-flex">
                  <button
                    className="btn btn-dark mt-5"
                    onClick={async () => {
                      const isSubmitted = await createCustomer();
                      if (isSubmitted) {
                        setActiveIndex((prev) =>
                          Math.min(prev + 1, items.length - 1)
                        );
                      }
                    }}
                    disabled={activeIndex === items.length - 1 || !isFormValid || loading}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Continue Payment
                  </button>
                </div>
              )}
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default ConfirmBooking;
