import axios from "axios";

//create Studio category
export const createStudioCategory = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/categories-management/create-category`,
    payload
  );
};

// get studio category
export const getStudioCategory = () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/categories-management/get-all-categories`
  );
};

//get studio category by id
export const getStudioCategoryById = (id) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/categories-management/get-category-by-id/${id}`
  );
};

//update studio category
export const updateStudioCategory = (id, payload) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/categories-management/update-category/${id}`,
    payload
  );
};


//delete studio category
export const deleteStudioCategory = (id) => {
    return axios.delete(
      `${process.env.REACT_APP_BASE_URL}/categories-management/delete-category-by-id/${id}`
    );
  };
