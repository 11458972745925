import React from 'react'
import * as Utils from "../../utils/utils.js"


const PaymentCard = () => {
    const getBookingData = Utils.getSelectedBooking()
  return (
<>
<div class="createBooking_Component">
    <div class='card-payment'>
        <div class="card-details">
            <h2 className='fw-bold'>Payment</h2>
           <div className='d-flex justify-content-between' style={{marginTop:'50px'}}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" alt="MasterCard Logo" class="mastercard-logo" width="40"/>           
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg" alt="Visa Card Logo" class="visa-logo" width="40"/>
            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="PayPal Logo" class="paypal-logo" width="40"/>
            </div>

            <div class="total" style={{marginTop:'50px'}}>
            TOTAL: £{getBookingData.rate}
            </div>
        </div>
    </div>
</div>
</>
  )
}

export default PaymentCard;
