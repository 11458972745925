import React, { useState, useEffect } from "react";
import Layout from "../../layouts/index";
import SEO from "../../components/seo";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import ScrollToTop from "../../components/scroll-to-top";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import * as auth from "../../shared/auth-service/auth";
import * as utils from "../../utils/utils";


function UserProfile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [userData, setUserData] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const history = useHistory();
  const [googleAuthUserInfo, setGoogleAuthUserInfo] = useState({});
  const location = useLocation();
  const baseUrl = `${process.env.REACT_APP_IMAGE_URL}/`;

  useEffect(() => {
    const existingData = utils.getUserData();
    setUserData(existingData);
    const _id = existingData?.body?._id || existingData?.data?.body?._id;
    if (_id) {
      getUserDataById(_id);
    }

    const query = new URLSearchParams(location.search);
    const userParam = query.get("user");
    const tokenParam = query.get("token");
    if (userParam) {
      const user = JSON.parse(decodeURIComponent(userParam));
      const { firstName, lastName, email, phone, _id , gmailPicture } = user;
      
      console.log('Extracted token from user object:', tokenParam);
      utils.setUserLoginToken(tokenParam)
      utils.saveUserData({ _id });
      setFirstName(firstName || "");
      setLastName(lastName || "");
      setEmail(email || "");
      setPhone(phone || "");
      setProfilePhoto(gmailPicture);
      setImagePreview(gmailPicture? gmailPicture: "");
      setGoogleAuthUserInfo({ firstName, lastName, email, phone, _id });
    console.log('googleeimagee',gmailPicture)
      
    }
  }, [location.search]);


  //validation
  const validate = () => {
    let isValid = true;

    const ukPhoneRegex = /^(07\d{9}|\(?07\d{3}\)?\s?\d{3}\s?\d{3})$/;
    if (!ukPhoneRegex.test(phone)) {
      setPhoneError("Phone number is invalid.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  //get user by id
  const getUserDataById = async (id) => {
    try {
      const response = await auth.getUserById(id);

      if (response?.data?.body) {
        const userData = response.data.body;
        setFirstName(userData.firstName || "");
        setLastName(userData.lastName || "");
        setEmail(userData.email || "");
        setPhone(userData.phone || "");
        setProfilePhoto(userData.profilePhoto?.imageUrl);
        setImagePreview(userData.profilePhoto?.imageUrl ?  userData.profilePhoto.imageUrl: "");
      }
    } catch (error) {}
  };


  const previewImage = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstName", firstName),
      formData.append("lastName", lastName),
      formData.append("email", email),
      formData.append("phone", phone),
      formData.append("profilePhoto", profilePhoto);
    if (validate()) {
      try {
        const googleAuthId = googleAuthUserInfo;
        const _id = userData?.body?._id || userData?.data?.body?._id || googleAuthId._id;
        const response = await auth.updateUser(_id, formData);
        utils.saveUserData(response);
        toast.success(response?.data?.result);
      } catch (error) {
        const { result } = error?.response?.data;
        toast.error(result);
      }
    }
  };

  //logout
  const logout = () => {
    utils.deleteToken();
    utils.deleteUserData();
    history.push("/login");
  };

  return (
    <>
      <ToastContainer />
      <Layout>
        <SEO title="Alexis || Account" />
        <div className="wrapper home-default-wrapper">
          <Header classOption="hb-border" />
          <div style={{ position: "relative", top: "90px" }}>
            <h1 className="text-center fw-bold">ACCOUNT</h1>
          </div>
          <div
            className="container vh-100"
            style={{ height: "670px", marginTop: "165px" }}
          >
            <div className="d-flex justify-content-between">
              <h3>My Profile</h3>
              <button className="btn btn-success" onClick={logout}>
                Logout
              </button>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <h5 className="mt-3">Upload your profile photo</h5>
                <div className="mb-3">
                  <div className="border p-3 text-center">
                    <img
                      src={
                        imagePreview
                          ? imagePreview
                          : "https://via.placeholder.com/150"
                      }
                      className="img-fluid mb-3"
                      alt="Profile Photo"
                      style={{ maxWidth: "150px" }}
                    />
                    <div>
                      <input
                        type="file"
                        id="uploadPhoto"
                        className="d-none"
                        accept="image/*"
                        onChange={previewImage}
                      />
                      <label
                        htmlFor="uploadPhoto"
                        className="btn btn-success me-2"
                      >
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-5 ">
                <h5>Contact detail</h5>
                <form onSubmit={updateUserData}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Scarlat"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Doe"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@homerealty.com"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Mobile number *</label>
                      <div className="input-group">
                        <span className="input-group-text">+44</span>
                        <input
                          type="text"
                          className="form-control"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="07123 456789"
                        />
                      </div>
                      {phoneError && (
                        <p className="text-danger mt-2">{phoneError}</p>
                      )}
                    </div>
                  </div>
                  <button type="submit" className="btn btn-secondary me-2 mt-4">
                    Update Info
                  </button>
                </form>
              </div>
            </div>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </>
  );
}

export default UserProfile;
