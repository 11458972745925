import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import StudioCategory from "../studio-category/studio-category";
import CreateBooking from "../create-booking/create-booking";
import BookingList from "../booking-list/booking-list";
import CustomersList from "../customers-list/customers-list";
import * as utils from "../../../utils/utils";
import Logo from "../../../assets/logo.jpeg"

function SideNav() {
  const history = useHistory();

  //logout
  const logout = () => {
    utils.deleteToken();
    utils.deleteUserData();
    history.push("/login");
  };

  return (
    <>
      <Router>
        <div className="main">
          <div style={{width:'340px'}}>
            <div className="border-bottom mb-3">
              <a className="navbar-brand ms-4" href="#">
                <img
                  src={Logo}
                  width="150"
                  height="57"
                />
              </a>
            </div>
            <ul className="mt-4 mb-4 nav-item">
              <li className="mb-4 ms-4">
                <NavLink
                  to="/sideNav/dashboard"
                  className="d-flex px-3"
                  role="button"
                >
                  <span className="icon me-2">
                    <i className="fa fa-home"></i>
                  </span>
                  <span className>Dashboard</span>
                </NavLink>
              </li>
              <li className="mb-4 ms-4">
                <NavLink
                  to="/sideNav/create-studio-category"
                  className="d-flex px-3"
                  role="button"
                  routernavlinkactive="activeColor"
                >
                  <span className="icon me-2">
                    <i className="fa fa-file-text"></i>
                  </span>
                  <span className routernavlinkactive="activeColor">
                    Create Category
                  </span>
                </NavLink>
              </li>
              <li className="mb-4 ms-4">
                <NavLink
                  to="/sideNav/create-booking"
                  className="d-flex px-3"
                  role="button"
                  routernavlinkactive="activeColor"
                >
                  <span className="icon me-2">
                    <i class="fa fa-check-circle-o"></i>
                  </span>
                  <span className routernavlinkactive="activeColor">
                    Create Booking
                  </span>
                </NavLink>
              </li>
              <li className="mb-4 ms-4">
                <NavLink
                  to="/sideNav/booking-list"
                  className="d-flex px-3"
                  role="button"
                  routernavlinkactive="activeColor"
                >
                  <span className="icon me-2">
                    <i class="fa fa-tasks"></i>
                  </span>
                  <span className routernavlinkactive="activeColor">
                    Booking List
                  </span>
                </NavLink>
              </li>
              <li className="mb-4 ms-4">
                <NavLink
                  to="/sideNav/customers-list"
                  className="d-flex px-3"
                  role="button"
                  routernavlinkactive="activeColor"
                >
                  <span className="icon me-2">
                    <i className="fa fa-group"></i>
                  </span>
                  <span className routernavlinkactive="activeColor">
                    Customers List
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="content">
            <nav
              className="navbar navbar-expand-lg navbar-light new-nav mb-2"
              aria-label="Eighth navbar example"
            >
              <div className="container-fluid">
                <div className="hamburger mx-4" role="button">
                  <i className="fa-solid fa-bars"></i>
                </div>
                <a className="navbar-brand x_secure" href="#"></a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <div
                        className="hamburger me-4 mt-2"
                        role="button"
                        ptooltip="Notifications"
                        tooltipposition="top"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasScrolling1"
                        aria-controls="offcanvasScrolling1"
                      >
                        <i className="fa-solid fa-bell"></i>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="hamburger me-4 mt-2">
                        <i
                          className="fa-solid fa-inbox "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal4"
                        ></i>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="hamburger me-4 mt-2">
                        <i
                          className="pi pi-user-plus "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                        ></i>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="hamburger me-4 mt-2">
                        <i
                          className="fa-solid fa-puzzle-piece"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal7"
                        ></i>
                      </div>
                    </li>
                    <div className="hamburger me-4 mt-2">
                      <i
                        className="fa-solid fa-magnifying-glass"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                      ></i>
                    </div>
                    <div
                      className="hamburger me-4 mt-2"
                      role="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasScrolling"
                      aria-controls="offcanvasScrolling"
                    >
                      <i className="fa-solid fa-question"></i>
                    </div>
                    <li className="nav-item dropdown mt-1">
                      <a
                        className="nav-NavLink dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-gear"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                      <div
                        className="dropdown-menu "
                        aria-labelledby="navbarDropdown"
                      >
                        <ul>
                          <li onClick={logout}>
                            <a className="dropdown-item sign_out">Sign out</a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="main-content">
              <Switch>
                <Route path="/sideNav/" exact component={Dashboard} />
                <Route path="/sideNav/dashboard" exact component={Dashboard} />
                <Route
                  path="/sideNav/create-studio-category"
                  exact
                  component={StudioCategory}
                />
                <Route
                  path="/sideNav/create-booking"
                  exact
                  component={CreateBooking}
                />
                <Route
                  path="/sideNav/booking-list"
                  exact
                  component={BookingList}
                />
                  <Route
                  path="/sideNav/customers-list"
                  exact
                  component={CustomersList}
                />
              </Switch>
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title mt-5 text-secondary mx-3"
                        id="exampleModalLabel"
                      >
                        Search Everything
                      </h1>
                      <button
                        type="button"
                        className="btn-close mt-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">...</div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal7"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-secondary mx-3"
                        id="exampleModalLabel"
                      >
                        Navbar
                      </h5>
                      <button
                        type="button"
                        className="btn-close mt-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">Side-bar and body</div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal3"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="container">
                        <div className="row my-3">
                          <div className="col-md-12">
                            <h4 className="my-3">Invite to work management</h4>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="my-3">
                            <label className="form-label">
                              Invite with email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                            />

                            <div className="form-check form-check-inline my-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                              />
                              <label className="form-check-label">Member</label>
                            </div>
                            <div className="form-check form-check-inline my-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                              />
                              <label className="form-check-label">
                                Viewer(Read-only)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary">
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal4"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-secondary mx-3"
                        id="exampleModalLabel"
                      >
                        Tabs
                      </h5>
                      <button
                        type="button"
                        className="btn-close mt-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">Sidediv</div>
                  </div>
                </div>
              </div>
              <div
                className="offcanvas offcanvas-end "
                style={{ marginTop: "70px", width: "600px" }}
                data-bs-scroll="true"
                data-bs-backdrop="false"
                tabIndex="-1"
                id="offcanvasScrolling1"
                aria-labelledby="offcanvasScrollingLabel"
              ></div>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
}

export default SideNav;
