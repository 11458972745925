import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../../layouts/footer";
import PageTitle from "../../../components/page-title";
import * as StudioCategoryService from "../../../shared/studio-category-service/studio-category";

function StudioCategory() {
  const [categoryName, setCategoryName] = useState("");
  const [modalCategoryName, setModalCategoryName] = useState("");
  const [Id, setId] = useState(null);
  const [studioCategoryData, setStudioCategoryData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5); // Rows per page

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const currentItems = studioCategoryData;

  useEffect(() => {
    getStudioCategory();
  }, []);

  // get studio category
  const getStudioCategory = async () => {
    const studioCategory = await StudioCategoryService.getStudioCategory();
    setStudioCategoryData(studioCategory.data.body);
  };

  //get studio category by id
  const getStudioCategoryById = async (id) => {
    const response = await StudioCategoryService.getStudioCategoryById(id);
    setModalCategoryName(response?.data?.body?.categoryName);
    setId(response?.data?.body?._id);
  };

  //update studio category by id
  const updateStudioCategoryById = async () => {
    try {
      const response = await StudioCategoryService.updateStudioCategory(Id, {
        categoryName: modalCategoryName,
      });
      toast.success(response?.data?.result);
      getStudioCategory();
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  //delete studio category by id
  const deleteStudioCategoryById = async (id) => {
    try {
      const response = await StudioCategoryService.deleteStudioCategory(id);
      setStudioCategoryData(
        studioCategoryData.filter((category) => category._id !== id)
      );
      toast.success(response?.data?.result);
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  //submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await StudioCategoryService.createStudioCategory({
        categoryName,
      });
      toast.success(response?.data?.result);
      getStudioCategory();
      setCategoryName("");
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="studioCategory_Component">
        <div className="page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <PageTitle
                  classOption="page-title-content content-style2 text-center"
                  subTitle="Create Studio Category"
                  title="We Are 8th Studio"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="container-fluid">
              <div className="studioCategory-container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="box1">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <h5 className="text-center fw-bold mt-4">
                            Create Studio Category
                          </h5>
                          <div className="studioCategoryField">
                            <div className="row">
                              <div className="col-md-8 mx-auto">
                                <label className="mb-3">Studio Category</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Studio category"
                                  value={categoryName}
                                  onChange={(e) =>
                                    setCategoryName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-success mt-5"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="box2 ">
                      <h5 className="text-center fw-bold mt-4">
                        List Studio Category
                      </h5>

                      {/* Prime React Table */}
                      <div>
                        <div className="p-inputgroup mt-4">
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                          </span>
                          <InputText
                            type="search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                          />
                        </div>

                        <DataTable
                          value={currentItems}
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                          rows={rows}
                          first={first}
                          onPage={onPageChange}
                          globalFilter={globalFilter}
                          emptyMessage="No studio category found."
                          responsiveLayout="scroll"
                        >
                          <Column
                            field="index"
                            header="#"
                            body={(rowData, options) =>
                              first + options.rowIndex + 1
                            }
                          ></Column>
                          <Column
                            field="categoryName"
                            header="Studio Category"
                          ></Column>
                          <Column
                            header="Edit"
                            body={(rowData) => (
                              <i
                                data-bs-toggle="modal"
                                data-bs-target="#updateModal"
                                className="fa fa-pencil text-info"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() =>
                                  getStudioCategoryById(rowData._id)
                                }
                              ></i>
                            )}
                          ></Column>
                          <Column
                            header="Delete"
                            body={(rowData) => (
                              <i
                                className="fa fa-trash-o text-danger"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() =>
                                  deleteStudioCategoryById(rowData._id)
                                }
                              ></i>
                            )}
                          ></Column>
                        </DataTable>
                      </div>
                      {/* Prime React Table */}
                      <div
                        className="modal fade"
                        id="updateModal"
                        tabIndex="-1"
                        aria-labelledby="updateModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog  modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="updateModalLabel">
                                Update Studio Category
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="mb-3">
                                <label className="form-label">
                                  Studio Category
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={modalCategoryName}
                                  onChange={(e) =>
                                    setModalCategoryName(e.target.value)
                                  }
                                  placeholder="Update Studio Category"
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                onClick={updateStudioCategoryById}
                                className="btn btn-success"
                              >
                                Confirm Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudioCategory;
