import React from "react";
import Layout from "../../layouts/index";
import { useHistory } from "react-router-dom";

const BookingConfirmation = () => {
  const history = useHistory();

  const handleNavigation = () => {
    history.push("/home");
  };
  return (
    <>
      <Layout>
        <div className="wrapper about-page-wrapper">
          <div className="createBooking_Component">
            <div class="payment-success-container">
              <div class="success-icon">
                <span class="checkmark">✔</span>
              </div>
              <div class="payment-details">
                <h5>Payment Successful</h5>
                <p>Thank you.Your payment has been processed successfully</p>
                <button className="btn btn-success" onClick={handleNavigation}>
                  Back to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BookingConfirmation;
