import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../layouts/index";
import SEO from "../../components/seo";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import ScrollToTop from "../../components/scroll-to-top";
import { useParams } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import * as auth from "../../shared/auth-service/auth";

export default function ResetPassword() {
  const [urlParams, setUrlParams] = useState({});
  const { id, token, verificationToken } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    // Store the params in state
    setUrlParams({
      _id: id,
      token: token,
      verificationToken: verificationToken,
    });

    // Log the params to the console
    console.log("params", { id, token, verificationToken });
  }, [id, token, verificationToken]);

  //password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  //confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  //validation
  const validate = () => {
    let isValid = true;
    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else {
      setPasswordError("");
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password.");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const { verificationToken, _id } = urlParams;
        const response = await auth.resetPassword({
          _id,
          verificationToken,
          password,
          confirmPassword,
        });
        toast.success(response?.data?.result);
        setTimeout(() => {
          utils.setUserLoginToken(response?.data?.body?.token);
          utils.saveUserData(response?.data);
          history.push("/login");
        }, 1000);
      } catch (error) {
        const { result } = error?.response?.data;
        console.log(error);
        toast.error(result);
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Layout>
        <SEO title="8th Street Studio || Reset Password" />
        <div className="wrapper home-default-wrapper">
          <Header classOption="hb-border" />
          <div style={{ position: "relative", top: "345px" }}>
            <h1 className="text-center fw-bold">RESET PASSWORD</h1>
          </div>

          <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="col-md-6 col-lg-6">
              <form onSubmit={resetPassword}>
                <div className="form-group mt-4">
                  <label htmlFor="newPassword">New Password</label>
                  <div className="input-group">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      id="newPassword"
                      placeholder="********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary input-group-text"
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={`fa ${
                          passwordVisible ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {passwordError && (
                    <p className="text-danger">{passwordError}</p>
                  )}
                </div>

                <div className="form-group mt-4">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="input-group">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      className="form-control"
                      id="confirmPassword"
                      placeholder="********"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary input-group-text"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      <i
                        className={`fa ${
                          confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {confirmPasswordError && (
                    <p className="text-danger">{confirmPasswordError}</p>
                  )}
                </div>
                <div className="d-flex  align-items-center mb-4 mt-2">
                  {/* Don't have an account? */}
                  <span className="ms-2">
                    <NavLink to="/forgot-password">Resend Reset Link</NavLink>
                  </span>
                </div>

                <div className="text-center mt-5">
                  <button type="submit" className="btn btn-primary">
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
}
