import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { NavLink, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";
import AboutService from "../../containers/about/about-service/index.jsx";
import BookingTitleContainer from "../../containers/global/booking-page-title/booking-page-title.js";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header/index";
import Layout from "../../layouts/index";
import TimeData from "../../data/time.json";
import * as StudioCategoryService from "../../shared/studio-category-service/studio-category.js";
import * as StudioBooking from "../../shared/studio-booking-service/studio-booking.js";
import * as Utils from "../../utils/utils.js";
import { ToastContainer, toast } from "react-toastify";


const Booking = () => {
  const [categories, setCategories] = useState([]);
  const [studioBookingList, setStudioBookingList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectCalendar, setSelectCalendar] = useState(false);
  const [timeList, setTimeList] = useState([]);
  const [category, setCategory] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("");
  const [bookingEndTime, setBookingEndTime] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const history = useHistory();
  useEffect(() => {
    getStudioCategory();
    getTimeList();
    setCurrentDateTime(new Date());
  }, []);

  // Format the date to show the full day name and other details
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  // get studio category
  const getStudioCategory = async () => {
    const studioCategory = await StudioCategoryService.getStudioCategory();
    const categoryData = studioCategory?.data?.body.map(
      ({ _id, categoryName }) => ({
        id: _id,
        name: categoryName,
      })
    );
    setCategories(categoryData);
  };

  // get studio category booking list
  const getStudioCategoryBookingList = async (id) => {
    const response = await StudioBooking.getStudioCategoryListById(id);
    setStudioBookingList(response?.data?.body);
  };

  // get time listing
  const getTimeList = () => {
    const timeListResponse = TimeData;
    setTimeList(timeListResponse);
  };

  //close all open accordions
  const closeAllAccordions = () => {
    document.querySelectorAll(".collapse.show").forEach((accordion) => {
      new bootstrap.Collapse(accordion).hide();
    });
  };

  //accordion handling function
  const handleButtonClick = (accordionId, id) => {
    setCategory(id);
    closeAllAccordions();
    const targetAccordion = document.getElementById(accordionId);
    if (targetAccordion) {
      new bootstrap.Collapse(targetAccordion).show();
    }
    getStudioCategoryBookingList(id);
  };

  // display calendar on selected id
  const selectDateTime = (id) => {
    if (id === selectedId) {
      setSelectCalendar(!selectCalendar);
    } else {
      setSelectedId(id);
      setSelectCalendar(true);
    }
  };

  //validation
  const validate = () => {
    let isValid = true;
    // Category validation
    if (!bookingStartTime) {
      toast.error("Booking start time is required");
      isValid = false;
    }

    // Rate validation
    if (!bookingDate) {
      toast.error("Booking date is required");
      isValid = false;
    }
    return isValid;
  };

  // create booking
  const createBooking = async () => {
    if (!validate()) return;
    try {
      const response = await StudioBooking.checkTimeSlot({
        category,
        bookingStartTime,
        bookingEndTime: "11:00 AM",
        bookingDate: Utils.formatDate(bookingDate),
        selectedHourlyRate:Utils.getSelectedBooking()._id
      });
      if (response?.data?.isData === true) {
        const data = {
          category,
          bookingStartTime,
          bookingEndTime: "11:00 AM",
          bookingDate: Utils.formatDate(bookingDate),
        };
        Utils.saveBookingDate(data);
        history.push("/confirm-booking", {
          category,
          bookingStartTime,
          bookingEndTime,
          bookingDate: Utils.formatDate(bookingDate),
        });
      } else {
        toast.error(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="createBooking_Component">
        <Layout>
          <SEO title="Alexis || Booking" />
          <div className="wrapper about-page-wrapper">
            <Header classOption="hb-border" />
            <div className="main-content">
              <BookingTitleContainer />
              <AboutService />
              <div className="team-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                      <h2 className="title">Choose Category</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container accordion_div">
                {categories.map(({ id, name }) => {
                  const accordionId = `flush-collapse-${id}`;
                  const buttonId = `heading-${id}`;
                  return (
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                      key={id}
                    >
                      <div className="accordion-item rounded-3 border-0 shadow mb-2">
                        <h2 className="accordion-header" id={buttonId}>
                          <button
                            className="accordion-button border-bottom collapsed fw-semibold d-flex justify-content-between align-items-center"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                          >
                            {name}
                            <span className="ms-auto">
                              <button
                                className="btn btn-dark"
                                data-bs-target={`#${accordionId}`}
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls={accordionId}
                                onClick={() => {
                                  handleButtonClick(accordionId, id);
                                  Utils.saveHourlyId(id);
                                }}
                              >
                                Select
                              </button>
                            </span>
                          </button>
                        </h2>
                        <div
                          id={accordionId}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                          aria-labelledby={buttonId}
                        >
                          {studioBookingList.map((bookingList) => {
                            return (
                              <div className="accordion-body">
                                <div className=" container-xxl booking p-0 p-md-0">
                                  <div
                                    class="p-3"
                                    style={{ maxWidth: "1000px" }}
                                  >
                                    <div class="row g-0 align-items-center">
                                      <div class="col-md-4">
                                        <img
                                          src={
                                            bookingList?.HourlyRateThumbnail
                                              ?.imageUrl
                                          }
                                          class="img-fluid rounded-start"
                                          alt="1 Hour Hire"
                                        />
                                      </div>
                                      <div class="col-12 col-md-5">
                                        <div class="card-body">
                                          <h5 class="card-title fw-bold fs-6">
                                            {bookingList?.hours} Hour Hire
                                          </h5>
                                          <p class="card-text">
                                            {bookingList?.hours} hour @ £
                                            {bookingList?.rate}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3 col-12 d-flex d-md-block justify-content-center justify-content-md-end text-end">
                                        <button
                                          class="btn w-50 btn-dark "
                                          onClick={() => {
                                            selectDateTime(bookingList?._id);
                                            Utils.saveSelectedBooking(
                                              bookingList
                                            );
                                          }}
                                        >
                                          Book
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Select Date and Time Start */}
                                  {selectCalendar &&
                                    selectedId === bookingList._id && (
                                      <div class="container mt-5">
                                        <div class="row">
                                          <div class="col-12 col-md-6">
                                            {/* <div class=""> */}
                                              <div class="card-body">
                                                <Calendar
                                                  value={bookingDate}
                                                  onChange={(e) => {
                                                    console.log(e.target.value);
                                                    setBookingDate(
                                                      e.target.value
                                                    );
                                                  }}
                                                  inline
                                                  minDate={new Date()}
                                                  className="w-100" 
                                                />
                                              </div>
                                            {/* </div> */}
                                          </div>

                                          <div class="col-12 col-md-6">
                                            <div class="">
                                              <div class="card-body">
                                                <h5 class="card-title">
                                                  {formattedDate}
                                                </h5>
                                                <p class="card-text">
                                                  TIME ZONE: LONDON (GMT+01:00)
                                                </p>
                                                <div className="form-group row">
                                                  <div className="col-12 mb-2">
                                                    <label>
                                                      Select Start Time
                                                    </label>
                                                    <select
                                                      className="form-select"
                                                      value={bookingStartTime}
                                                      onChange={(e) => {
                                                        setBookingStartTime(
                                                          e.target.value
                                                        );
                                                      }}
                                                    >
                                                      <option value="" disabled>
                                                        Start Time
                                                      </option>
                                                      {timeList.map(
                                                        (response) => {
                                                          return response.data.map(
                                                            (item) => (
                                                              <option
                                                                key={item.id}
                                                                value={
                                                                  item.time
                                                                }
                                                              >
                                                                {item.time}
                                                              </option>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  </div>

                                                  {/* <div className="col-6 mb-2">
                                                    <label>
                                                      Select End Time
                                                    </label>
                                                    <select
                                                      className="form-select"
                                                      value={bookingEndTime}
                                                      onChange={(e) => {
                                                        setBookingEndTime(
                                                          e.target.value
                                                        );
                                                      }}
                                                    >
                                                      <option value="" disabled>
                                                        End Time
                                                      </option>
                                                      {timeList.map(
                                                        (response) => {
                                                          return response.data.map(
                                                            (item) => (
                                                              <option
                                                                key={item.id}
                                                                value={
                                                                  item.time
                                                                }
                                                              >
                                                                {item.time}
                                                              </option>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  </div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <button
                                            onClick={createBooking}
                                            className="btn btn-dark"
                                          >
                                            Continue
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  {/* Select Date and Time End */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Footer />
            <ScrollToTop />
          </div>
        </Layout>
      </div>
    </React.Fragment>
  );
};

export default Booking;
