import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../layouts/footer";
import PageTitle from "../../../components/page-title";
import * as StudioCategoryService from "../../../shared/studio-category-service/studio-category";
import * as StudioCategoryBooking from "../../../shared/studio-booking-service/studio-booking";

function CreateBooking() {
  const [imagePreview, setImagePreview] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [hours, setHours] = useState("");
  const [rate, setRate] = useState("");
  const [HourlyRateThumbnail, setHourlyRateThumbnail] = useState("");

  
  useEffect(() => {
    getStudioCategory();
  }, []);


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const image = e.target.files && e.target.files[0];
      setHourlyRateThumbnail(image);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  // get studio category
  const getStudioCategory = async () => {
    const studioCategory = await StudioCategoryService.getStudioCategory();
    const categoryData = studioCategory?.data?.body.map(
      ({ _id, categoryName }) => ({
        id: _id,
        name: categoryName,
      })
    );
    setCategories(categoryData);
  };


  //validation
  const validate = () => {
    let isValid = true;
  
    // Category validation
    if (!category) {
      toast.error("Category is required.");
      isValid = false;
    }
  
    // Hours validation
    if (!hours) {
      toast.error("Hours are required.");
      isValid = false;
    } else if (isNaN(hours) || hours <= 0) {
      toast.error("Hours must be a positive number.");
      isValid = false;
    }
  
    // Rate validation
    if (!rate) {
      toast.error("Rate is required.");
      isValid = false;
    } else if (isNaN(rate) || rate <= 0) {
      toast.error("Rate must be a positive number.");
      isValid = false;
    }
  
    // Thumbnail validation
    if (!HourlyRateThumbnail) {
      toast.error("An image must be uploaded.");
      isValid = false;
    }
    return isValid;
  };


  const createStudioBooking = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const formData = new FormData();
    formData.append("category", category),
      formData.append("hours", hours),
      formData.append("rate", rate),
      formData.append("HourlyRateThumbnail", HourlyRateThumbnail);
    try {
      const response = await StudioCategoryBooking.createBooking(formData);
      toast.success(response?.data?.result);
    } catch (error) {
      const { result } = error?.response?.data;
      toast.error(result);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="createBooking_Component">
      <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle="Create Studio Booking"
                            title="We Are 8th Studio"  
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="createBooking-div">
              <div className="container-fluid  d-flex align-items-center justify-content-center">
                <div className="row w-100">
                  {/* Left Side - Profile Image and Edit Section */}
                  <div
                    className="col-lg-5 d-flex flex-column align-items-center justify-content-center text-white p-4"
                    style={{
                      backgroundColor: "#4D536B",
                      borderRadius: "20px 0 0 20px",
                    }}
                  >
                    <div className="profile-pic-container">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Product"
                          className="rounded"
                          style={{
                            width: "250px",
                            height: "250px",
                            objectFit: "cover",
                            border: "4px solid white",
                          }}
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/150"
                          alt="Product"
                          className="rounded"
                          style={{
                            width: "250px",
                            height: "250px",
                            objectFit: "cover",
                            border: "4px solid white",
                          }}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="d-none"
                      id="productImageUpload"
                    />
                    <label
                      htmlFor="productImageUpload"
                      className="btn btn-light mt-3"
                    >
                      Upload Image
                    </label>
                    <p className="mt-3">You can upload image (jpg or png)</p>
                  </div>

                  {/* Right Side - Form Section */}
                  <div
                    className="col-lg-7 bg-white p-5"
                    style={{ borderRadius: "0 20px 20px 0" }}
                  >
                    <div className="text-center mb-4">
                      <h2>Create Studio Booking</h2>
                    </div>
                    <form>
                      <div className="form-group row">
                        <div className="col">
                          <label>Select Category</label>
                          <select
                            className="form-select"
                            value={category}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setCategory(selectedValue); 
                            }}
                          >
                            <option value="" disabled>
                              Select category
                            </option>
                            {categories.map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <label>Enter Hours</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="1"
                            value={hours}
                            onChange={(e) => setHours(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <label className="mt-4">Enter Rate</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="£75.00"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                        />
                      </div>

                      <div className="form-group mt-4 text-center">
                        <button
                          onClick={createStudioBooking}
                          className="btn btn-secondary btn-block w-50"
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBooking;
